import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/global/Layout"
import Blocks from "../components/templates/Blocks"

export default function WhyUs({ data }) {
    return (
        <Layout>
            {data.file.childMarkdownRemark.frontmatter.sections.map((section) => {
                if (section.type === 'awards') {
                    return (
                        <>
                            <div className="section">
                                <div className="container">
                                    <Blocks
                                        heading={section.heading}
                                        awards={section.awards}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
            })}
        </Layout>
    )
}

export const query = graphql`
{
    file(sourceInstanceName: {eq: "main"}) {
      childMarkdownRemark {
        frontmatter {
          sections {
            body
            heading
            link
            text
            type
            background_image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 100)
              }
            }
            awards {
              description
              heading
              icon
            }
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 50)
              }
            }
          }
        }
      }
    }
  }
`